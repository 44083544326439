import React, {Component} from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';

const STRIPE_KEY = process.env.GATSBY_STRIPE_PUB_KEY;

function stripeProvider(WrappedComponent) {
    return class extends Component {
        render() {
            if (STRIPE_KEY && typeof window !== 'undefined') {
                return (
                    <StripeProvider
                        apiKey={STRIPE_KEY}
                    >
                        <Elements
                            fonts={
                                [{
                                    cssSrc: 'https://fonts.googleapis.com/css?family=Lato'
                                }]
                            }
                        >
                            <WrappedComponent {...this.props}/>
                        </Elements>
                    </StripeProvider>
                )   
            } else {
                return (
                    <div/>
                )
            }
        }
    }
  }



export default stripeProvider;