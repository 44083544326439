import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { bgIcon } from '../../../styles/global'
import { media } from '../../../styles/utils'

class Checkbox extends Component {

    state = {
        selected: this.props.value
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.value !== this.state.selected) {
            this.setState({ selected: nextProps.value })
        }
    }

    handleClick = (val) => {
        // this.setState({ selected: val });
        this.props.onCheckboxChange(val);
    }

    render() {
        const { selected } = this.state;
        const { theme } = this.props;
        return (
            <Container
                onClick={() => this.handleClick(!selected)}
            >
                <Check
                    selected={selected}
                    theme={theme}
                />
                <Info>
                    <Title>{this.props.title}</Title>
                    <Description>{this.props.description}</Description>
                </Info>
            </Container>
        );
    }
}

const Container = styled.div`
    position: relative;
    display: flex;
    user-select: none;
`;

const Check = styled.div`
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${bgIcon};
    margin-right: 12px;

    width: 23px;
    height: 23px;
    background-image: url(${props => props.selected ? '' : '' });
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.div`
    line-height: 24px;
    font-size: 18px;
`

const Description = styled.div`
    line-height: 24px;
    font-size: 18px;
`

export default Checkbox;
