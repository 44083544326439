import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import styled, {css, keyframes, injectGlobal} from "styled-components";
import {rgba } from "polished";
import {find, capitalize, findIndex} from "lodash";
import { Link, navigate } from 'gatsby'
import {StripeProvider, Elements, injectStripe} from 'react-stripe-elements';

import { Layout, Input, Footer, MetaTags } from '../../components'
import Checkbox from './components/Checkbox' 
import StripeForm from './components/StripeForm' 
import stripeProvider from "./components/stripeProvider";
import { handleRegisterPayment } from '../../services/auth';

import {media, width, isClient} from "../../styles/utils";
import {container, bgIcon, bgImage, hoverState, button, padding} from "../../styles/global";
import {data} from "./data";

import logo from '../../assets/images/logo.png'
import genericBG from '../../assets/images/generic-bg.jpg'
import loader from '../../assets/images/loader.svg'

import { fetchSettings, updateSettings } from '../../store/settings'
import { getIntitalState, getFieldProps, checkValidation, getPosForElement } from "./utils";
import { inputStyle } from "../../components/Input/Input";
import { lightOrange, orange, darkGrey } from "../../styles/colors";


//https://stripe.com/docs/testing#cards

//4242424242424242  - all good
//4000000000000002  - declined
//4000000000000069  - expired
//4100000000000019  - high risk level
//4242424242424241  - incorrect number


class Register extends Component {

	state = {
		validate: false,
		fields: {},
		submitting: false,
        stripeToken: null,
        registrationComplete: false,
        showServerErrors: false,
		serverErrorMessage: '',
		isExistingUser: false,
		globalValidation: {
			error: false,
			message: null
		}
	}

	componentWillMount = () => {
        // Generate initial state for field data
        
		this.setState({
            fields: getIntitalState(data.fields),  
			acf_json: JSON.parse(this.props.pageContext.acf_json),
			landing_json: JSON.parse(this.props.pageContext.landing_json)
		})
	}

	componentDidMount() {
		if (isClient()) {
			if (window.fbq) {
				fbq('track', 'InitiateCheckout');
			}
		}
	}
	
	handleFieldChange = (key, value, validator) => {
        this.setState({
            fields: {
				...this.state.fields,
				[key]: {
					value: value,
					valid: validator ? validator(value) : true,

				}
			}
        })
	}

	componentDidUpdate(prevProps, prevState) {
		const { isExistingUser } = this.state;
		const { existingUser } = this.state.fields; 

		if (prevState.fields['exisitingUser']?.value !== existingUser?.value) {		
			if (existingUser?.value && !isExistingUser) {
				this.setState({isExistingUser: true})
			}

			if (!existingUser?.value && isExistingUser) {
				this.setState({isExistingUser: false})
			}
		}
	}
	

	handleSubmit = (params) => {
		const { fields, submitting } = this.state;

		// Show valiation errors

		this.setState({ validate: true })

		// Submit if all fields valid

		if (!submitting && this.checkValidation()) {

			this.setState({ 
				submitting: true,
				globalValidation: {
					error: false,
					message: null
				}
			})
					
			this.processStripe();
		}	
    }

	processStripe = () => {
		const { fields, isExistingUser } = this.state;
		const { settings, stripe } = this.props;
		
		let tokenData = {
            name: `${fields.billingFirstName.value} ${fields.billingLastName.value}`,
            firstname: fields.billingFirstName.value,
            lastname: fields.billingLastName.value,
            username: fields.billingEmail.value,
            email: fields.billingEmail.value,
            password: fields.password.value,
            phone: fields.billingPhone.value,
			company: fields.billingCompany.value,
        } 
        
        // console.log('fields',fields);
        // console.log('tokenData',tokenData);
        // return false

		stripe.createToken(tokenData).then(payload => {
			if (payload.error) {

				// Validation
				
                this.setState({ 
					submitting: false,
                    globalValidation: {
                        error: true,
			            message: payload.error.message
					}
				})
				
				this.scrollToStripeError()

			} else {

                tokenData.stripeToken = payload.token.id;

				this.setState({ 
					stripeToken: payload.token.id,
				})

                // Submit data to backend


                handleRegisterPayment(tokenData)
                    .then(response => {  
                       
                        console.log('handleRegisterPayment response',response);

                        if (response.success) {

                            this.setState({
                                registrationComplete: true
							})

							if (isClient() && window.fbq && response.cost) {
								const cost = parseInt(response.cost);
								window.fbq('track', 'Purchase', {value: cost / 100, currency: 'USD'});
							}

							navigate('/register/thankyou')
							
                        } else {
                            this.setState({
                                showServerErrors: true,
                                serverErrorMessage: response.message,
                                submitting: false
                            })
                        }
                    })				
			}
		})
	}

	getPriceString = () => {
	  
	}
	
	scrollToStripeError = () => {
		const element = document.getElementById('stripe-fields');

		window.scrollTo({
			top: getPosForElement(element),
			behavior: "smooth"
		});
	}
	
	checkValidation = () => {
		const { fields, isExistingUser } = this.state
		const { settings } = this.props;		

		// Scroll to error?

		const firstError = find(fields, (o) => {
			if (o.valid == false) {
				return true
			}
		})	
		
		if (firstError && firstError.name) {
			const element = document.getElementById(firstError.name);
					
			window.scrollTo({
				top: getPosForElement(element),
				behavior: "smooth"
			});
		}

		console.log('valid?', checkValidation(fields, isExistingUser))

		return checkValidation(fields, isExistingUser)
	}
	
	renderCheckout = () => {
		const { globalValidation, billingDuration } = this.state;
		const { settings } = this.props;

		return (
			<Totals>
				<Total>{this.getPriceString()}</Total>

				<Submit
                    onClick={this.handleSubmit}
                    //onClick={this.processStripe}
				>
					{this.state.submitting ? <Loading/> : 'Submit Payment'}
				</Submit>

				{globalValidation.error && (
					<Error>{globalValidation.message}</Error>
				)}
			</Totals>
		)
	}

	renderField = (i, data, validate, disabled) => {
		const { settings } = this.props;
		const fieldState = this.state.fields[data.name];
		const CustomComponent = data.component;
		
		const props = {
			onChangeValue: (value, validator) => this.handleFieldChange(data.name, value, validator),
			...getFieldProps(i, data, validate, disabled, settings, fieldState),
			...fieldState,
			existingUserHide: data.existingUserHide,
			component: data.component,
		}
		
		// // Render custom component if available
		
		// if (CustomComponent) {
		// 	return (
		// 		<CustomComponent
		// 			key={i}
		// 			fieldState={this.state.fields}
		// 			{...props}
		// 		/>
		// 	)
        // }

		// Else render with input

		return (
			<Input
				{...props}
			/>
		)
	}
	
	renderFields = () => {
		const { validate, createSubscriptionError, isExistingUser } = this.state;
		
		
		return (
			<Fields
				isExistingUser={isExistingUser}
			>
				{data.fields.map((field, i) => {
					return this.renderField(i, field, validate)
				})}
			</Fields>
		)
	}

	renderStripe = () => {
		const { validate, createSubscriptionError } = this.state;

		return (
			<StripeForm
				stripeFields={data.stripeFields}
				paymentError={createSubscriptionError}
			/>
		)
    }
    
    renderServerErrors = () => {
		const { showServerErrors, serverErrorMessage } = this.state;
        if(!showServerErrors) return 

		return (
            <Error>{serverErrorMessage}</Error>
        )  
    }

  	render() {	
        const { registrationComplete, acf_json, landing_json } = this.state;

		return (
			<Layout>
                <MetaTags 
                    title={`Adventures in the Field`}
                />
				<Wrapper
					bgImage={genericBG}
				>
					<Container>

                        <Logo 
                            to={'/'}
                        />

                        <Content>
                            <Title>Adventures in the Field</Title>

                            <Heading>Registration & Payment</Heading>

                            {!registrationComplete && (
                                <Form>
                                    <Description
										dangerouslySetInnerHTML={{__html:acf_json.registration_description}}
										//dangerouslySetInnerHTML={{__html:landing_json.registration_text}}
                                    />

                                    {this.renderFields()}
                                    {this.renderStripe()}
                                    {this.renderCheckout()}
                                    {this.renderServerErrors()}
                                </Form>  
                            )}
                    
                        </Content>

					</Container>
				</Wrapper>
                <Footer
					copyrightOnly
				/>
			</Layout>
		)
	}	 
}

const Wrapper = styled.div`
    ${bgImage}
    background-image: url(${props => props.bgImage});
    min-height: 100vh;
`

const Container = styled.div`
	${container};
	${padding};
    display: flex;
    flex-direction: column;
    align-items: center; 
`

const Logo = styled(Link)`
    position: absolute;
    top: 24px;
    left: 24px;
    ${bgImage}
    background-image: url(${logo});
    width: 165px;
    height: 134px;
`

const Content = styled.div`
    display: flex;
	flex-direction: column;
    background-color: rgba(255, 255, 255, 0.76); 
    border-radius: 21.3px;
    padding: 23px;  
	margin: 64px 32px 120px;
    max-width: 580px;
	z-index: 3;

	${media.phone`
		margin: 180px 0 120px;
		background: white;
	`}
`

const Title = styled.div`
    text-transform: uppercase;
    font-size: 24px;
    line-height: 47px;
    letter-spacing: 12px;
    color: ${orange};
    max-width: 320px;
    margin-bottom: 56px;

	${media.phone`
        font-size: 20px;
        line-height: 30px;
        text-align: center;
		margin-bottom: 32px;
    `}
`

const Heading = styled.div`
    font-family: 'LatoWebLight';
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 0.3em;
    color: #4d4d4d;
    margin-bottom: 40px;

	${media.phone`
		margin-bottom: 24px;
	`}
`

const Subheading = styled.div``
const Description = styled.div`
	font-size: 16px;
    margin-bottom: 0;
	color: ${darkGrey};

	a {
		color: #dfc0b0;
		border-bottom: 1px solid #dfc0b0;
		${hoverState}
	}
`
const Select = styled.select``

// Form

const Form = styled.div`
`

const Thanks = styled.div`

    font-family: 'LatoWebLight';
    font-size: 20px;
    color: #4d4d4d;
    margin-top: 60px;
`

// Form Sections

export const fieldsWrapper = css`
	margin-top: 32px;
	display: flex;
	flex-flow: row wrap;
	max-width: 580px;

	/* Field styles */

	> * {
		margin-bottom: 20px;
		flex: 1 0 100%;
	}
`

const Fields = styled.div`
	${fieldsWrapper}

	select {
		${inputStyle}
		flex: 1 0 100%;
		background: white;
	}

	[data-name="subscription_length"] {
		margin-top: 32px;
	}

	${props => {
		if (props.isExistingUser) return css`
			.new-user-field {
				display: none;
			}
		`
	}}
`

// Checkout

const Totals = styled.div`
	display: flex;
	flex-direction: column;
`

const Total = styled.div``
const Submit = styled.div`
	${button}
`

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const Loading = styled.div`
	width: 28px;
	height: 28px;
    background-image: url(${loader});
	${bgIcon}
	animation: ${rotate360} 1s linear infinite;
`

const Error = styled.div`
	line-height: 16px;
	font-size: 13px;
	letter-spacing: -0.01px;
    margin-top: 16px;
    color: red;
	text-align: center;
`

const LoginLink = styled(Link)``

const mapDispatchToProps = dispatch => ({
    fetchSettings() {
        dispatch(fetchSettings())
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(stripeProvider(injectStripe(Register)));
