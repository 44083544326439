import React, {Component} from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { rgba } from 'polished'
import { injectStripe } from 'react-stripe-elements'

import { bgIcon } from '../../../styles/global';
import { fieldsWrapper } from '../index'
import { inputStyle } from '../../../components/Input/Input';

import { media } from '../../../styles/utils';
import { lightOrange, orange } from '../../../styles/colors';

class StripeForm extends Component {

    //https://stripe.com/docs/testing#cards
    
    //4242424242424242  - all good
    //4000000000000002  - declined
    //4000000000000069  - expired
    //4100000000000019  - high risk level
    //4242424242424241  - incorrect number

    createOptions = (extraStyles) => {
        return {
            style: {
                base: {
                    background: 'white',
                    fontSize: '16px',
                    color: orange,
                    fontFamily: 'Lato, serif',
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                        color: lightOrange,
                    }
                },
                invalid: {
                    color: 'red',
                },
            },
            classes: {
                invalid: 'invalid',
                focus: 'focus'
            }
        }
    }

    handleFieldChange = (key, value) => { 
        this.setState({ [key]: value })
    }

    renderCustomFields = () => {
        const { customFields, renderField, handleFieldChange, validate } = this.props;
        
        // Create custom fields with render function and validation from parent

        return customFields && customFields.map((field, i) => {
            return renderField(i, field, validate)
        })
    }

    renderPaymentError = () => {
        return (
            <div
                className={`paymentError`}            
            >
                Your card was declined, please try again or use a different card.
            </div>
        )
    }

    renderStripeFields = () => {
        const { stripeFields } = this.props;

        return stripeFields.map((field, i) => {
            const Element = field.component;
            return (
                <div
                    key={i}
                    className={`field ${field.class}`}
                >
                    <Element
                        placeholder={field.placeholder}
                        {...this.createOptions()}
                    />
                </div>
            )
        })
    }

    render() {
        const { paymentError } = this.props;

        return (
            <Wrapper
                className={'stripe-form'}
            >
                <GlobalStyles/>

                <div
                    className='top'
                >
                    {paymentError && this.renderPaymentError()}
                </div>
        
                <div
                    className='field-wrapper'
                    id={'stripe-fields'}
                >
                    {this.renderCustomFields()}
                    {this.renderStripeFields()}
                </div> 

            </Wrapper>
        )

    }

}

// NOTE: 
// Styled components converted to css`` and
// injected globally due to stripe elements SSR issue w/ Gatsby

const Wrapper = styled.div``

const Heading = styled.div`
	line-height: 48px;
	font-size: 36px;
`

// Top

const Top = css`
    display: flex;
    max-width: 580px;
    align-items: flex-end;

    ${media.tablet`
        flex-direction: column;
        align-items: flex-start;
    `}
`

const PaymentError = css`
    background: red;
    max-width: 377px;
    margin-left: auto;
    background: rgba(203, 0, 0, 0.2);

    line-height: 16px;
    font-size: 13px;
    letter-spacing: -0.01px;
    color: red;
    padding: 12px;

    ${media.tablet`
        max-width: auto;
        margin-left: 0;
        margin-top: 14px;
    `}
`

const Cards = css`
    display: flex;
    margin-top: 20px;
    ${bgIcon}
    width: 124px;
    height: 30px;
`

// Fields

const Fields = css`
    margin-top: 32px;
	display: flex;
	flex-flow: row wrap;
	max-width: 580px;

	/* Field styles */

	> * {
		margin-bottom: 20px;
		flex: 1 0 100%;
	}
`
const Field = css`
    .StripeElement {
        ${inputStyle}
        display: block;
        padding-top: 9px;
        background: white;

        &.focus {
            outline: none;
        }

        &.invalid {
            &, &::placeholder {
                color: red;
            }

            &, &:focus {
                border: 1px solid red;
                box-shadow: 0px 0px 0px 1px red;
            }
        }
    }

    /* Custom classes */

    &.cardNumber {
        flex: 1 0 100%;
        padding-right: 0;
    }

    &.cardCVV {
        padding-right: 20px;
    }
`

// Validation 

const Error = css`
    font-size: 12px;
    margin-top: 8px;
    color: red;
`

// Convert SC to injectGlobal classes

const GlobalStyles = createGlobalStyle`
    .stripe-form {
        
        .top {
            ${Top}
        }

        .paymentError {
            ${PaymentError}
        }

        .cards {
            ${Cards}
        }

        .field-wrapper {
            ${Fields}
        }

        .field {
            ${Field}
        }

        .error {
            ${Error}
        }
    }
`

export default StripeForm;